<template>
  <div>
    <h1 class="h2">{{ Title }}</h1>

    <div class="my-5">
      <Btn flat color="primary" icon="backspace" @click="back">戻る</Btn>
      <Btn
        class="ml-3"
        color="info"
        icon="details"
        :disabled="edit"
        @click="(edit = true), (add = false)"
        >編集</Btn
      >
    </div>

    <v-form
      v-model="valid"
      ref="form"
      :disabled="!edit"
      @submit.prevent="submit"
    >
      <v-autocomplete
        v-model="selectShozokuGroup"
        :items="shozokuGroupList"
        :item-text="item => item.refcode.concat(' ', item.name)"
        item-value="code"
        label="所属グループ"
        outlined
        chips
        :readonly="canadd"
        :rules="[Rules.Required]"
        @change="
          () => {
            shozokuList = shozokuGroupList.filter(
              e => e.code != selectShozokuGroup
            );
          }
        "
      >
        <template v-slot:prepend-item>
          <v-list-item ripple>
            <v-dialog v-model="dialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-content v-bind="attrs" v-on="on">
                  <v-list-item-title>
                    所属グループ追加
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  所属グループ追加
                </v-card-title>
                <v-card-text>
                  <v-text-field v-model="addName" label="グループ名" />
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialog = false">
                    戻る
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="addList(addName)">
                    追加
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
      </v-autocomplete>
      <v-autocomplete
        v-model="selectShozoku"
        :items="shozokuList"
        :item-text="item => item.refcode.concat(' ', item.name)"
        item-value="code"
        label="所属"
        outlined
        chips
        multiple
        :rules="[Rules.Required]"
      />
      <div class="d-flex flex-row-reverse">
        <v-btn type="login" color="success" :disabled="!edit">
          完了
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import Common from "@/mixins/common";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";

export default {
  name: "ShozokuGroup",
  mixins: [Common, Forms, Api, ShowDialogs],
  data() {
    return {
      addId: "",
      addName: "",
      dialog: false,
      args: "",
      params: null,
      add: false,
      edit: false,
      shozokuList: [],
      shozokuGroupList: [],
      selectShozoku: "",
      selectShozokuGroup: ""
    };
  },
  computed: {
    Title() {
      return (
        this.params.title +
        " " +
        (this.add ? "追加" : this.edit ? "編集" : "詳細")
      );
    },
    canadd() {
      if (this.add === true) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;
      console.log("submit", this.id);
      //コンボボックスはフォーカスが外れないと値を更新しない
      if (this.$refs.Combobox) {
        this.$refs.Combobox.forEach(e => e.blur());
      }
      this.$loading();
      try {
        const ids = [];
        const shozokuList = this.selectShozoku.map(e => Number(e));
        const shozokuGroup = Number(this.selectShozokuGroup);
        const groupList = this.shozokuGroupList;
        const group = groupList.find(e => e.code === this.selectShozokuGroup);

        const param = {
          shozokuIds: shozokuList,
          groupId: shozokuGroup,
          groupName: group.name
        };

        console.log("submit_param", param);
        if (this.add) {
          param.datakbn = 1;
          await this.$post(this.Paths.shozokuDetail, param);
        } else {
          await this.$put(this.Paths.shozokuDetail, param);
        }
        this.$info("更新しました。", "担当マスタ");
        this.edit = false;
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    back() {
      const path = "/list/shozoku";
      this.$router.push({
        path: path,
        query: { filter: this.params.filter }
      });
    },
    async getShozokuList(groupId) {
      console.log("getShozokuList");

      const param = groupId;
      const encode = encodeURI(JSON.stringify(param));
      const result = await this.$get(
        this.Paths.shozokuDetailPull,
        "query=" + encode
      );
      return result;
    },
    async getId() {
      console.log("getId");
      return await this.$get(this.Paths.shozokuDetailId);
    },
    async getGroupList() {
      console.log("getGroupList");

      const param = "";
      const encode = encodeURI(JSON.stringify(param));
      const result = await this.$get(
        this.Paths.shozokuDetailPullGroup,
        "query=" + encode
      );
      return result;
    },
    addList(addName) {
      const addId =
        Math.max(...this.shozokuGroupList.map(e => Number(e.code))) + 1;
      this.shozokuGroupList.push({
        code: String(addId),
        name: addName,
        refcode: "",
        option1: "",
        option2: "",
        option3: "",
        option4: ""
      });
      this.selectShozokuGroup = String(addId);
      this.dialog = false;
    }
  },
  async created() {
    console.log(this.name, "created");
    const route = this.$route.params;
    const args = route.args;
    this.params = route;
    this.args = route.args;
    console.log("filter", this.params.filter);

    this.$loading();
    try {
      if (this.params.prosess && typeof this.params.prosess === "string")
        this.params.prosess = this.params.prosess.toLowerCase();

      switch (this.params.prosess) {
        case "add":
          this.add = true;
          this.edit = true;
          break;
        case "edit":
          this.add = false;
          this.edit = true;
          break;
        default:
          break;
      }

      this.shozokuGroupList = await this.getGroupList();

      if (!this.add) {
        this.shozokuList = await this.getShozokuList(args.groupId);
        this.shozokuGroupList = await this.getGroupList();

        this.selectShozokuGroup = String(args.groupId);
        const shozokuList = args.concatIncode.split(",");
        this.selectShozoku = shozokuList;
      } else {
        this.shozokuList = await this.getShozokuList(0);
        this.shozokuGroupList = await this.getShozokuList(0);

        this.id = await this.getId();
      }
    } catch (e) {
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  }
};
</script>

<style></style>
